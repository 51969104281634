import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ScrollToTop from "react-scroll-to-top";
import img1 from '../images/m-wrg-2.webp';

export default function MeltemWrgS() {
    const { t } = useTranslation();

    return (
        <Container fluid  >
            <ScrollToTop />
            <div  class='row justify-content-md-center mbg1 '>
                <div class='col-xxl-5 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 m-auto text-center '>
                    <h3 className='mh3p1'>{t('Ventilation.Meltem.c1')}</h3>
                    <h3 className='mh3p'>{t('Ventilation.Meltem.c2')}</h3>
                    <img src={img1} width={600} height={384} alt="m-wrg-device" class='img-fluid' />
                </div>
                <div class='col-xxl-5 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 m-auto text-center pt-2'>
                    <div className="d-grid gap-3">
                        <Button href={t('Ventilation.Meltem.linkwrg1-1')} target="_blank" variant="secondary" size="lg">
                            {t('Ventilation.Meltem.lwrg1-1')}
                        </Button>
                        <Button href={t('Ventilation.Meltem.linkwrg1-2')} target="_blank" variant="secondary" size="lg">
                            {t('Ventilation.Meltem.lwrg1-2')}
                        </Button>
                        <Button href={t('Ventilation.Meltem.linkwrg1-3')} target="_blank" variant="secondary" size="lg">
                            {t('Ventilation.Meltem.lwrg1-3')}
                        </Button>
                        <Button href={t('Ventilation.Meltem.linkwrg1-4')} target="_blank" variant="dark" size="lg">
                            {t('Ventilation.Meltem.lwrg1-4')}
                        </Button>
                    </div>
                </div>
            </div>


        </Container>
    )
}