import { useEffect } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ScrollToTop from "react-scroll-to-top";
import Container from 'react-bootstrap/Container';
import img1 from '../images/top.webp';
import img2 from '../images/forrest.webp';
import img3 from '../images/ourVision.webp';
import img4 from '../images/ourPhilosophy.webp';
import img5 from '../images/heatpump.webp';
import img6 from '../images/ventilation.webp';
import img7 from '../images/ourMission.webp';
import Footer from '../Components/footer';
import GoToTop from '../Components/GoToTop';

function topFunction() {
    // const element = document.getElementById("navbar");
    // const height = element.offsetHeight;
    // document.getElementById("top").style.paddingTop = height + "px";
}

export default function Home() {
    const { t } = useTranslation();

    useEffect(() => {
        window.addEventListener('load', topFunction());
        return () => window.removeEventListener('load', topFunction());
    }, []);

    return (

        <Container fluid className='bck' >
             <ScrollToTop />
            <div id='top' className='row mx-0' >
                <div className='col-12 px-0'>
                    <div className='row '>
                        <div className='col-12 text-center px-0'>
                            <img
                                width={1920}
                                height={720}
                                src={img1}
                                className="img-fluid opc"
                                alt="Geotermalgroup"
                            />
                        </div>
                    </div>
                </div>

                <div className='row papirus'>
                    <div className='col-lg-5 col-md-5 col-sm-5 col-1'>
                    </div>
                    <div className='col-lg-7 col-md-7 col-sm-7 col-11'>
                        <p>{t("main.ourModern")}</p>

                    </div>
                </div>
            </div>
            <div className='row mtop mt-5 va-center '>
                <div className="col-xxl-10 col-xl-10 col-lg-10">
                    <h3 className='mh3 m-0'>{t("main.welcomeTo1")} </h3>
                    <h3 className='mh3 '>{t("main.welcomeTo2")} </h3>
                </div>
            </div>
            <div className='row mtop mt-5 va-center  '>
                <div className='col-xxl-3 col-xl-4 col-lg-4 col-md-5 col-sm-4 col-8 mt2 justify-content: center shadow1'>
                    <img
                        width={770}
                        height={605}
                        src={img2}
                        className="img-fluid opc"
                        alt="forrest"
                    />
                </div>
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-7 pt-3 px-4'>
                    <div> <h1 className='nowrap mh1'>{t("main.weCare")} </h1><h1 className='h1M'>{t("main.weCare1")} </h1></div>
                    <div ><h1 className='nowrap mh1'>{t("main.weCare")} </h1><h1 className='h1M'>{t("main.weCare2")} </h1></div>
                    <div ><h1 className='nowrap mh1'>{t("main.weCare")} </h1><h1 className='h1M'>{t("main.weCare3")} </h1></div>
                    <div ><h3 className='h3M'>{t("main.weCare4")} </h3></div>
                </div>
            </div>

            <hr className='m-5  ' />

            <div className='row mtop va-center pt-0 '>
                <div className=' col-lg-10 col-md-12 col-sm-12 shadowtext flex bckg2'>
                    <div className=' col-xxl-5 col-xl-5 col-lg-7 col-md-7 col-sm-7 col-12'>
                        <h3 className='mh3p1'>{t("main.ourVision")} </h3>

                        <h3 className='mh3p'>{t("main.ourVision1")} </h3>
                        <h3 className='mh3p'>{t("main.ourVision2")} </h3>
                    </div>
                    <div className='col-xxl-4 col-xl-5 col-lg-4 col-md-4 col-sm-5 d-none d-sm-block shadow1 mx-3'>
                        <img
                            width={550}
                            height={848}
                            src={img3}
                            className="img-fluid "
                            alt="OurVision"
                        />
                    </div>
                </div>
            </div>

            <div className='row mtop va-center py-5 '>
                <div className=' col-lg-10 col-md-12 col-sm-12 shadowtext flex  bckg1'>
                    <div className='col-xxl-4 col-xl-5 col-lg-4 col-md-4 col-sm-5 d-none d-sm-block shadow1 mx-3'>
                        <img
                            width={550}
                            height={733}
                            src={img4}
                            className="img-fluid opc"
                            alt="OurPhilosophy"
                        />
                    </div>
                    <div className=' col-xxl-5 col-xl-5 col-lg-7 col-md-7 col-sm-7 col-12 '>
                        <h3 className='mh3p1'>{t("main.ourPhilosophy")} </h3>

                        <h3 className='mh3p'>{t("main.ourPhilosophy1")} </h3>
                        <h3 className='mh3p'>{t("main.ourPhilosophy2")} </h3>
                    </div>

                </div>
            </div>

            <div className='row mtop  va-center pt-0 '>
                <div className=' col-lg-10 col-md-12 col-sm-12 shadowtext flex bckg2'>
                    <div className=' col-xxl-5 col-xl-5 col-lg-7 col-md-7 col-sm-7 col-12 '>
                        <h3 className='mh3p1'>{t("main.ourMission")} </h3>

                        <h3 className='mh3p'>{t("main.ourMission1")} </h3>
                        <h3 className='mh3p'>{t("main.ourMission2")} </h3>
                    </div>
                    <div className='col-xxl-4 col-xl-5 col-lg-4 col-md-4 col-sm-5 d-none d-sm-block shadow1 mx-3'>
                        <img
                            width={550}
                            height={367}
                            src={img7}
                            className="img-fluid opc"
                            alt="OurMission"
                        />
                    </div>
                </div>
            </div>
            <hr className='m-3  ' />
            <div className='row mtop     '>
                <div className='col-lg-12 col-md-12 '>
                    <h3 className='h1M1 text-center mb-0'>{t("main.ourSolutions")} </h3>
                </div>
            </div>
            <hr className='m-3  ' />
            <div className='row mtop   va-center bckg2 py-5 '>
                <div className='col-xl-10 col-lg-6 col-md-6 text-start'>
                    <h1 className=' h1M1'>{t("main.ourSolutions1")} </h1>
                </div>
                <div className='col-xxl-6 col-xl-6 col-lg-7 col-md-7'>
                    <p className=' mh3p'>{t("main.ourSolutions2")} </p>
                </div>
                <div className='col-lg-5 col-xl-4 col-md-5 col-sm-8 text-center'>
                    <img
                        width={550}
                        height={848}
                        src={img5}
                        className="img-fluid "
                        alt="HeatPumps"
                    />
                </div>
            </div>
            <div className='row mtop pt-5 va-center bckg1 shadow1 pb-5'>
                <div className='col-xl-10 col-lg-6 col-md-6 text-start'>
                    <h1 className=' h1M1'>{t("main.ourSolutions3")} </h1>
                </div>
                <div className='col-xxl-6 col-xl-6 col-lg-7 col-md-7'>
                    <p className=' mh3p'>{t("main.ourSolutions4")} </p>
                </div>
                <div className='col-lg-5 col-xl-4 col-md-5 col-sm-8 text-center'>
                    <img
                        width={550}
                        height={848}
                        src={img6}
                        className="img-fluid "
                        alt="Ventilation"
                    />
                </div>
            </div>
            <Footer />
            <GoToTop />
        </Container>

    )
}