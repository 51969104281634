import { useEffect } from "react";
import { useLocation } from "react-router-dom";
  

export default function GoToTop() {
  const routePath = useLocation();
  const onTop = () => {
    // const element = document.getElementById("navbar");
    const height = "109";
    document.getElementById("top").style.paddingTop = height  + "px";
    console.log(document.getElementById("top").style.paddingTop + 'padding');
    window.scrollTo(0, 0);

  }


  useEffect(() => {
  onTop();
    
  }, [routePath]);
  
  return null;
}