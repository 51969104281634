import React from 'react';
import Container from 'react-bootstrap/Container';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import img1 from '../images/apa1.webp';
import img2 from '../images/apa2.webp';
import img3 from '../images/apa3.webp';

function topFunction() {
    const element = document.getElementById("navbar");
    const height = element.offsetHeight;
    document.getElementById("top").style.paddingTop = height + "px";
}

export default function OchsnerApaMenajera() {

    const { t } = useTranslation();

    useEffect(() => {
        window.addEventListener('load', topFunction());
        return () => window.removeEventListener('load', topFunction());
    }, []);

    return (
        <Container fluid  >
            <div id='ApaMenajera' className='row stick1 mbg1 mtop'>
                <div className='col-md-10'>
                    <h3 className='my-3 h1M1 text-center'>{t('heatPumps.ApaMenajera.a1')}</h3>
                </div>
            </div>
            <div className='row  mbg1 pt-5' >
                <div className='col-md-5 col-6 text-center'>
                    <img src={img1} height="400" width="450" className="img-fluid" alt="Pompe-Apa-Menajera" />
                </div>
                <div className='col-md-5 col-6 text-center'>
                    <img src={img2} height="400" width="450" className="img-fluid" alt="Pompe-Apa-Menajera" />
                </div>
            </div>

            <div className='row  mbg1 mtop'>
                <div className='col-md-10'>
                    <h3 className=' mh3p '>{t('heatPumps.ApaMenajera.a2')}</h3>
                </div>
            </div>

            <div className='row  mbg1 mtop py-5'>
                <div className='col-lg-4 col-md-4 text-center mx-5 my-auto '>
                    <img src={img3} height="404" width="695" className="img-fluid" alt="Pompe-Sol-Apa" />
                </div>
                <div className="col-lg-5 col-md-5 my-auto">
                    <hr className='hr1' />
                    <h3 className=' mh3p '>{t('heatPumps.ApaMenajera.a3')}</h3>
                    <hr className='hr1' />
                </div>
            </div>

            <div className='row  mbg1 mtop pb-5'>
                <div className='col-md-10'>
                    <h3 className='my-3 mh3p1 text-center'>{t('heatPumps.ApaMenajera.a4')}</h3>
                </div>
                <div className='col-md-10'>
                    <h3 className=' mh3p '>{t('heatPumps.ApaMenajera.a5')}</h3>
                </div>
            </div>

        </Container>
    )
}