import React from 'react';
import Container from 'react-bootstrap/Container';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import img1 from '../images/sol-apa1.webp';
import img2 from '../images/sol-apa2.webp';
import img3 from '../images/sol-apa3.webp';


function topFunction() {
    const element = document.getElementById("navbar");
    const height = element.offsetHeight;
    document.getElementById("top").style.paddingTop = height + "px";
}

export default function OchsnerSolApa() {

    const { t } = useTranslation();

    useEffect(() => {
        window.addEventListener('load', topFunction());
        return () => window.removeEventListener('load', topFunction());
    }, []);

    return (
        <Container fluid  >
            <div id='SolApa' className='row stick1 mbg1 mtop'>
                <div className='col-md-10'>
                    <h3 className='my-3 h1M1 text-center'>{t('heatPumps.SolApa.a1')}</h3>
                </div>
            </div>
            <div className='row  mbg1 pt-5 ' >
                <div className='col-md-10 col-12 text-center'>
                    <img src={img1} height="400" width="985" className="img-fluid" alt="Pompe-Aer-Apa"/>
                </div>
            </div>

            <div className='row  mbg1 mtop'>
                <div className='col-md-10'>
                    <h3 className='my-3 mh3p1 text-center'>{t('heatPumps.SolApa.a2')}</h3>
                </div>
                <div className='col-md-10'>
                    <h3 className=' mh3p '>{t('heatPumps.SolApa.a3')}</h3>
                </div>
            </div>

            <div className='row  mbg1 mtop py-5'>
                <div className='col-lg-4 col-md-4 text-center mx-5 my-auto '>
                    <img src={img2} height="400" width="450" className="img-fluid" alt="Pompe-Sol-Apa"/>
                </div>
                <div className="col-lg-5 col-md-5">
                    <hr className='hr1' />
                    <h3 className=' mh3p '>{t('heatPumps.SolApa.a4')}</h3>
                    <hr className='hr1' />
                </div>
            </div>

            <div className='row  mbg1 mtop'>
                <div className='col-md-10'>
                    <h3 className='my-3 mh3p1 text-center'>{t('heatPumps.SolApa.a5')}</h3>
                </div>
                <div className='col-md-10'>
                    <h3 className=' mh3p '>{t('heatPumps.SolApa.a6')}</h3>
                </div>
            </div>

            <div className='row  mbg1 mtop'>
                <div className='col-lg-3 col-md-3 text-center mx-5 my-auto '>
                    <img src={img3} height="181" width="260" className="img-fluid" alt="Pompe-Sol-Apa"/>
                </div>
                <div className="col-lg-6 col-md-6">
                    <hr className='hr1' />
                    <h3 className=' mh3p '><b>{t('heatPumps.SolApa.a7')}</b></h3>
                    <h3 className=' mh3p '>{t('heatPumps.SolApa.a8')}</h3>
                    <hr className='hr1' />
                </div>
            </div>

            <div className='row  mbg1 mtop '>
                <div className="col-lg-10 col-md-10">
                    <h3 className=' mh3p '><b>{t('heatPumps.SolApa.a9')}</b></h3>
                    <h3 className=' mh3p '>{t('heatPumps.SolApa.a10')}</h3>
                </div>
            </div>

            <div className='row  mbg1 mtop'>
                <div className='col-md-10'>
                    <h3 className='my-3 mh3p1 text-center'>{t('heatPumps.SolApa.a11')}</h3>
                </div>
                <div className='col-md-10'>
                    <h3 className=' mh3p '><b>{t('heatPumps.SolApa.a12')}</b></h3>
                    <h3 className=' mh3p pt-0'>{t('heatPumps.SolApa.a13')}</h3>
                    <h3 className=' mh3p '><b>{t('heatPumps.SolApa.a14')}</b></h3>
                    <h3 className=' mh3p pt-0'>{t('heatPumps.SolApa.a15')}</h3>
                    <h3 className=' mh3p '><b>{t('heatPumps.SolApa.a16')}</b></h3>
                    <h3 className=' mh3p pt-0'>{t('heatPumps.SolApa.a17')}</h3>
                </div>
            </div>

        </Container>
    )
}