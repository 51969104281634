import React from 'react';
import Container from 'react-bootstrap/Container';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import img1 from '../images/aer-apa1.webp';
import img2 from '../images/aer-apa2.webp';
import img3 from '../images/aer-apa3.webp';
import img4 from '../images/aer-apa4.webp';
import img5 from '../images/FE.webp';
import img6 from '../images/aer-apa5.webp';
import img7 from '../images/aer-apa6.webp';

function topFunction() {
    const element = document.getElementById("navbar");
    const height = element.offsetHeight;
    document.getElementById("top").style.paddingTop = height + "px";
}

export default function OchsnerAerApa() {

    const { t } = useTranslation();

    useEffect(() => {
        window.addEventListener('load', topFunction());
        return () => window.removeEventListener('load', topFunction());
    }, []);
    return (
        <Container fluid  >
            <div id='AerApa' className='row stick1 mbg1 mtop'>
                <div className='col-md-10 '>
                    <h3 className='my-3 h1M1 text-center'>{t('heatPumps.AerApa.a1')}</h3>
                </div>
            </div>
            <div className='row  mbg1 pt-5 '>
                <div className='col-md-10 col-12 text-center'>
                    <img src={img1} height="400" width="985" className="img-fluid" alt="Pompe-Aer-Apa" />
                </div>
            </div>
            <div className='row  mbg1 mtop'>
                <div className='col-md-10'>
                    <h3 className='my-3 mh3p1 text-center'>{t('heatPumps.AerApa.a2')}</h3>
                </div>
                <div className='col-md-10'>
                    <h3 className=' mh3p '>{t('heatPumps.AerApa.a3')}</h3>
                </div>
            </div>
            <div className='row  mbg1 mtop '>
                <div className='col-md-4 text-center'>
                    <img src={img2} height="400" width="450" className="img-fluid" alt="Pompe-Aer-Apa" />
                </div>
                <div className='col-md-4 text-center'>
                    <img src={img3} height="400" width="450" className="img-fluid" alt="Pompe-Aer-Apa" />
                </div>
                <div className='col-md-4 text-center'>
                    <img src={img4} height="400" width="450" className="img-fluid" alt="Pompe-Aer-Apa" />
                </div>
            </div>

            <div className='row  mbg1 mtop py-5'>
                <div className='col-lg-1 col-md-2 text-center mx-5 my-auto '>
                    <img src={img5} height="69" width="100" className="img-fluid" alt="Pompe-Aer-Apa" />
                </div>
                <div className="col-lg-6 col-md-7">
                    <hr className='hr1' />
                    <h3 className='my-3 mh3p1 text-center'>{t('heatPumps.AerApa.a4')}</h3>
                    <h3 className=' mh3p '>{t('heatPumps.AerApa.a5')}</h3>
                    <hr className='hr1' />
                </div>
            </div>

            <div className='row  mbg1 mtop'>
                <h3 className='my-3 mh3p1 text-center'>{t('heatPumps.AerApa.a6')}</h3>
                <div class="col-lg-2 col-md-1 text-center my-auto">
                    <img src={img6} height="44" width="49" className="img-fluid" alt="Pompe-Aer-Apa" />
                </div>
                <div className='col-md-9'>
                    <h3 className=' mh3p '>{t('heatPumps.AerApa.a7')}</h3>
                </div>
            </div>
            <div className='row  mbg1 mtop py-5'>
                <div className="col-lg-6 col-md-6">
                    <hr className='hr1' />
                    <h3 className=' mh3p '><b>{t('heatPumps.AerApa.a8')}</b></h3>
                    <h3 className=' mh3p '>{t('heatPumps.AerApa.a9')}</h3>
                    <hr className='hr1' />
                </div>
                <div className='col-lg-3 col-md-3 text-center mx-5 my-auto '>
                    <img src={img7} height="506" width="217" className="img-fluid" alt="Pompe-Sol-Apa" />
                </div>
            </div>


        </Container>
    );
}


