import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './custom.css';
import Top from '../src/Components/Top'
import { Suspense } from 'react';

function App() {
  
  return (
    <Suspense fallback={<div>Loading</div>}>
    <div class='mbg'>
      <Top />
    </div>
    </Suspense>
  );
}


export default App;
