import React from 'react';
import Container from 'react-bootstrap/Container';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import img1 from '../images/apa-apa1.webp';
import img2 from '../images/apa-apa2.webp';
import img3 from '../images/apa-apa3.webp';
import img4 from '../images/apa-apa4.webp';


function topFunction() {
    const element = document.getElementById("navbar");
    const height = element.offsetHeight;
    document.getElementById("top").style.paddingTop = height + "px";
}

export default function OchsnerApaApa() {

    const { t } = useTranslation();

    useEffect(() => {
        window.addEventListener('load', topFunction());
        return () => window.removeEventListener('load', topFunction());
    }, []);

    return (
        <Container fluid  >
            <div id='ApaApa' className='row stick1 mbg1 mtop'>
                <div className='col-md-10'>
                    <h3 className='my-3 h1M1 text-center'>{t('heatPumps.ApaApa.a1')}</h3>
                </div>
            </div>
            <div className='row  mbg1 pt-5' >
                <div className='col-md-10 col-12 text-center'>
                    <img src={img1} height="400" width="985" className="img-fluid" alt="Pompe-Apa-Apa" />
                </div>
            </div>

            <div className='row  mbg1 mtop'>
                <div className='col-md-10'>
                    <h3 className='my-3 mh3p1 text-center'>{t('heatPumps.ApaApa.a2')}</h3>
                </div>
                <div className='col-md-10'>
                    <h3 className=' mh3p '>{t('heatPumps.ApaApa.a3')}</h3>
                </div>
            </div>

            <div className='row  mbg1 mtop py-5'>
                <div className='col-lg-4 col-md-4 text-center mx-5 my-auto '>
                    <img src={img2} height="400" width="450" className="img-fluid" alt="Pompe-Sol-Apa" />
                </div>
                <div className="col-lg-5 col-md-5">
                    <hr className='hr1' />
                    <h3 className=' mh3p '>{t('heatPumps.ApaApa.a4')}</h3>
                    <hr className='hr1' />
                </div>
            </div>

            <div className='row  mbg1 mtop'>
                <div className='col-md-10'>
                    <h3 className='my-3 mh3p1 text-center'>{t('heatPumps.ApaApa.a5')}</h3>
                </div>
                <div className='col-md-10'>
                    <h3 className=' mh3p '>{t('heatPumps.ApaApa.a6')}</h3>
                </div>
            </div>

            <div className='row  mbg1 mtop py-5'>
                <div className='col-lg-4 col-md-4 text-center mx-5 my-auto '>
                    <img src={img3} height="270" width="260" className="img-fluid" alt="Pompe-Sol-Apa" />
                    <img src={img4} height="270" width="260" className="img-fluid" alt="Pompe-Sol-Apa" />
                </div>
                <div className="col-lg-6 col-md-6">
                    <h3 className=' mh3p1'>{t('heatPumps.ApaApa.a7')}</h3>
                    <h3 className=' mh3p '>{t('heatPumps.ApaApa.a8')}</h3>
                </div>
            </div>

            <div className='row  mbg1 mtop'>
                <div className='col-md-10'>
                    <h3 className='my-3 mh3p1 text-center'>{t('heatPumps.ApaApa.a9')}</h3>
                </div>
                <div className='col-md-10'>
                    <h3 className=' mh3p '><b>{t('heatPumps.ApaApa.a10')}</b></h3>
                    <h3 className=' mh3p pt-0'>{t('heatPumps.ApaApa.a11')}</h3>
                    <h3 className=' mh3p '><b>{t('heatPumps.ApaApa.a12')}</b></h3>
                    <h3 className=' mh3p pt-0'>{t('heatPumps.ApaApa.a13')}</h3>
                    <h3 className=' mh3p '><b>{t('heatPumps.ApaApa.a14')}</b></h3>
                    <h3 className=' mh3p pt-0'>{t('heatPumps.ApaApa.a15')}</h3>
                    <h3 className=' mh3p '><b>{t('heatPumps.ApaApa.a16')}</b></h3>
                    <h3 className=' mh3p pt-0'>{t('heatPumps.ApaApa.a17')}</h3>
                </div>
            </div>

        </Container>
    )
}