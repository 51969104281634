import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import logo from '../images/logo1.webp';
import logoMini from '../images/logo.webp';
import { useTranslation } from 'react-i18next';
// import MapGoogle from './MapGoogle';

function Footer() {

    const { t } = useTranslation();

    return (
        <Container className='p-0' fluid>
            <div class='row bGray shadow1  va-center'>
                <div class='col-xxl-4 col-xl-4 col-lg-4 col-md-4 d-none d-md-block  my-3 text-center'>
                    <img
                        src={logo}
                        height="80"
                        width="280"
                        className="d-inline-block align-center"
                        alt="Logo"
                    />
                </div>
                <div class=' col-sm-4 d-md-none d-sm-block  my-3 text-center'>
                    <img
                        src={logoMini}
                        height="80"
                        width="80"
                        className="d-inline-block align-center"
                        alt="Logo"
                    />
                </div>
                <div class='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 my-3 text-center mblink m-auto'>
                    <Nav.Link as={Link} to="/HeatPumps">{t('menu.heatPumps')}</Nav.Link>
                    <Nav.Link as={Link} to="/Ventilation">{t('menu.ventilation')}</Nav.Link>
                </div>
                <div class='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 my-3 text-center mblink m-auto text-center'>
                    <p>(+373) 787 53 333</p>
                    <a href="mailto:info@geotermalgroup.com">info@geotermalgroup.com</a>
                </div>

            </div>

        </Container>
    );
}


export default Footer;