import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ScrollToTop from "react-scroll-to-top";
import img1 from '../images/m-wrg-standard.webp';

export default function MeltemWrg2() {
    const { t } = useTranslation();

    return (
        <Container fluid  >
            <ScrollToTop />
            <div id='modele' class='row justify-content-md-center mbg1' >
                <div class='col-xxl-5 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 m-auto text-center '>
                    <h3 className='mh3p1'>{t('Ventilation.Meltem.d1')}</h3>
                    <h3 className='mh3p'>{t('Ventilation.Meltem.d2')}</h3>
                    <img src={img1} width={600} height={384} alt="m-wrg-device" class='img-fluid' />
                </div>
                <div class='col-xxl-5 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 m-auto text-center'>
                    <div className="d-grid gap-3">
                        <Button href={t('Ventilation.Meltem.linkwrg2-1')} target="_blank" variant="secondary" size="lg">
                            {t('Ventilation.Meltem.lwrg2-1')}
                        </Button>
                        <Button href={t('Ventilation.Meltem.linkwrg2-2')} target="_blank" variant="secondary" size="lg">
                            {t('Ventilation.Meltem.lwrg2-2')}
                        </Button>
                        <Button href={t('Ventilation.Meltem.linkwrg2-3')} target="_blank" variant="secondary" size="lg">
                            {t('Ventilation.Meltem.lwrg2-3')}
                        </Button>
                        <Button href={t('Ventilation.Meltem.linkwrg2-4')} target="_blank" variant="dark" size="lg">
                            {t('Ventilation.Meltem.lwrg2-4')}
                        </Button>
                    </div>
                </div>
            </div>


        </Container>
    )
}