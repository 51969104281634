import React from 'react';
// import { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';
import ScrollToTop from "react-scroll-to-top";
import img from '../images/pump1.gif';
import img2 from '../images/pump2.webp'
import img3 from '../images/aer-apa.webp'
import img4 from '../images/sol-apa.webp'
import img5 from '../images/apa-apa.webp'
import img6 from '../images/apa.webp'
import AerApa from '../Components/OchsnerAerApa'
import SolApa from '../Components/OchsnerSolApa'
import ApaApa from '../Components/OchsnerApaApa'
import OchsnerApaMenajera from '../Components/OchsnerApaMenajera';
import Footer from '../Components/footer';
import GoToTop from '../Components/GoToTop';

// function topFunction() {
//     // const element = document.getElementById("navbar");
//     // const height = element.offsetHeight;
//     // document.getElementById("top").style.paddingTop = height + "px";
// }

export default function Ochsner() {
    const { t } = useTranslation();

    // useEffect(() => {
    //     window.addEventListener('load', topFunction());
    //     return () => window.removeEventListener('load', topFunction());
    // }, []);
    return (
        <Container fluid  >
            <ScrollToTop />
            <div id='top' className=' row justify-content-md-center mbg1 mtop'>
                <div className='col-lg-12 col-xxl-10 col-md-10'>
                    <h3 className='my-3 mh3p1'> {t('heatPumps.Ochsner.a1')}</h3>
                    <h3 className='my-3 mh3p'>{t('heatPumps.Ochsner.a2')}</h3>
                </div>
            </div>
            <div class='row mbg justify-content-md-center mbg1 mtop'>
                <div class='col-md-10 col-lg-9 text-center'>
                    <img src={img} height="619" width="1100" class="img-fluid" alt="Principiu de functionare" />
                </div>
            </div>

            <div class='row justify-content-md-center pt-5 mbg1 mtop'>
                <div class='col-md-10'>
                    <h3 class='my-3 mh3p1'> {t('heatPumps.Ochsner.b1')}</h3>
                </div>
            </div>

            <div class='row  justify-content-md-center mtop'>
                <div class='col-xxl-5 col-xl-6 col-lg-6  d-none d-lg-block  '>
                    <img src={img2} class="par1 img-fluid" alt="from nature" />
                </div>

                <div class='col-md-5 col-sm-5 col-6  d-md-block d-sm-block d-block d-lg-none ptm  '>
                    <img src={img2} class="par1 img-fluid" alt="from nature" />
                </div>

                <div class='col-xxl-5 col-xl-6 col-lg-6 col-md-10 col-sm-12 mbg1 '>
                    <h3 className='mh3p'>{t('heatPumps.Ochsner.b2')}</h3>
                    <h3 className='mh3p'>{t('heatPumps.Ochsner.b3')}</h3>
                    <h3 className='mh3p1 pt-3'>{t('heatPumps.Ochsner.b4')}</h3>
                    <h3 className='mh3p'>{t('heatPumps.Ochsner.b5')}</h3>
                </div>
            </div>

            <div class='row justify-content-md-center mbg1 mtop'>
                <div class='col-md-10'>
                    <h3 class='my-3 mh3p1'>{t('heatPumps.Ochsner.c1')}</h3>
                </div>
            </div>

            <div class='row mtop justify-content-center mbg1 px-3'>
                <div class='col-lg-3 col-md-5 col-sm-6 col-xs-8 text-center mh3p1'>
                    <div class='justify-content-center m-3' >
                        <a href="/Ochsner#AerApa">
                            <img src={img3} class="img-fluid" alt="Aer-Apa" />
                            <h5>{t('heatPumps.Ochsner.c2')}</h5>
                        </a>
                    </div>
                </div>

                <div class='col-lg-3 col-md-5 col-sm-6 col-xs-8 text-center'>
                    <div class='justify-content-center m-3 mh3p1'>
                        <a href="/Ochsner#SolApa">
                            <img src={img4} class="img-fluid" alt="Sol-Apa" />
                            <h5>{t('heatPumps.Ochsner.c3')}</h5>
                        </a>
                    </div>
                </div>
                <div class='col-lg-3 col-md-5 col-sm-6 col-xs-8 text-center '>
                    <div class='justify-content-center m-3 mh3p1'>
                        <a href="/Ochsner#ApaApa">
                            <img src={img5} class="img-fluid" alt="Apa-Apa" />
                            <h5>{t('heatPumps.Ochsner.c4')}</h5>
                        </a>
                    </div>
                </div>
                <div class='col-lg-3 col-md-5 col-sm-6 col-xs-8 text-center'>
                    <div class='justify-content-center m-3 mh3p1'>
                        <a href="/Ochsner#ApaMenajera">
                            <img src={img6} class="img-fluid" alt="Apa Menajera" />
                            <h5>{t('heatPumps.Ochsner.c5')}</h5>
                        </a>
                    </div>
                </div>
            </div>

            <div class='row  mbg1 mtop pb-5'>
                <div class='col-md-10'>
                    <h3 class='my-3 mh3p1'>{t('heatPumps.Ochsner.d1')}</h3>
                    <h3 class=' mh3p'>{t('heatPumps.Ochsner.d2')}</h3>
                    <ul >
                        <li class='mh3'>{t('heatPumps.Ochsner.d2a')}</li>
                        <li class='mh3'>{t('heatPumps.Ochsner.d2b')}</li>
                        <li class='mh3'>{t('heatPumps.Ochsner.d2c')}</li>
                    </ul>
                    <h3 class='mh3'>{t('heatPumps.Ochsner.d3')}</h3>
                    <h3 class='mh3'>{t('heatPumps.Ochsner.d4')}</h3>
                </div>
            </div>
            <hr className='hr1' />
            <AerApa />
            <hr className='hr1' />
            <SolApa />
            <hr className='hr1' />
            <ApaApa />
            <hr className='hr1' />
            <OchsnerApaMenajera />
            <Footer />
            <GoToTop />
        </Container>
    );

}
