import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from '../Pages/Home';
import HeatPumps from '../Pages/HeatPumps';
import Ochsner from '../Pages/Ochsner';
import Meltem from '../Pages/Meltem';
// import Ventilation from '../Pages/Ventilation';
import ContactUs from '../Pages/ContactUs';
import NavDropdown from 'react-bootstrap/NavDropdown';
import enImg from '../images/en.webp';
import roImg from '../images/ro.webp';
import ruImg from '../images/ru.webp';
import uaImg from '../images/ua.webp';
import logo from '../images/logo1.webp';
import React from 'react';
import { useTranslation } from 'react-i18next';

window.onscroll = function () { scrollFunction() };

function scrollFunction() {
    if (document.body.scrollTop > 5 || document.documentElement.scrollTop > 80) {
        document.getElementById("navbar").style.padding = "0px 0px";
        document.getElementById("logo").style.width = "175px";

    } else {
        document.getElementById("navbar").style.padding = "10px 0px";
        document.getElementById("logo").style.width = "280px";

    }
}

function Top() {

    const { t, i18n } = useTranslation();
    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);

    }
    return (

        <Router>
            <Navbar id='navbar' collapseOnSelect expand="sm" className="text-center fixed-top  bWhite ">
                <Container className='opc' >
                    <Navbar.Brand className='m-0' href="/">
                        <img id='logo'
                            src={logo}
                            height="80"
                            width="280"
                            className="img-fluid  align-center"
                            alt="Logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className=" mlink m-auto ">
                            <Nav.Link eventKey='1' className="px-3" as={Link} to="/">{t('menu.home')}</Nav.Link>
                            <NavDropdown title={t('menu.products')} id="basic-nav-dropdown" className="m-auto">
                                <Nav>
                                    <NavDropdown title={t('menu.heatPumps')} id="basic-nav-dropdown" className='dropend' >
                                        <NavDropdown.Item className='dd' eventKey='2' as={Link} to="./Ochsner">Ochsner</NavDropdown.Item>
                                    </NavDropdown>
                                </Nav>
                                <NavDropdown.Divider />
                                <Nav>
                                    <NavDropdown title={t('menu.ventilation')}id="basic-nav-dropdown" className='dropend' >
                                        <NavDropdown.Item className='dd' eventKey='9' as={Link} to="./Meltem">Meltem</NavDropdown.Item>
                                    </NavDropdown>
                                </Nav>
                                {/* <NavDropdown.Item eventKey='3' as={Link} to="/Ventilation">{t('menu.ventilation')}</NavDropdown.Item> */}
                            </NavDropdown>
                            <Nav.Link eventKey='4' className="mp px-3 " as={Link} to="/ContactUs">{t('menu.contactUs')}</Nav.Link>

                            <NavDropdown title={t('menu.language')} id="basic-nav-dropdown" className="menuButton m-auto">
                                <NavDropdown.Item eventKey='5' class='p-0' onClick={() => changeLanguage("en")}>
                                    <img
                                        src={enImg}
                                        height="30"
                                        width="30"
                                        className="d-inline-block align-center btnLng"
                                        alt="EN"
                                    />EN

                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item eventKey='6' class='p-0' onClick={() => changeLanguage("ro")}>
                                    <img
                                        src={roImg}
                                        height="30"
                                        width="30"
                                        className="d-inline-block align-center btnLng"
                                        alt="RO"
                                    />RO

                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item eventKey='7' class='p-0' onClick={() => changeLanguage("ua")}>
                                    <img
                                        src={uaImg}
                                        height="30"
                                        width="30"
                                        className="d-inline-block align-center btnLng"
                                        alt="UA"
                                    />UA

                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item eventKey='8' class='p-0' onClick={() => changeLanguage("ru")}>
                                    <img
                                        src={ruImg}
                                        height="30"
                                        width="30"
                                        className="d-inline-block align-center btnLng"
                                        alt="RU"
                                    />RU

                                </NavDropdown.Item>

                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Routes>
                <Route path='/' element={<Home />} />
                {/* <Route path='/HeatPumps' element={<HeatPumps />} /> */}
                {/* <Route path='/HeatPumps' element={<HeatPumps />} /> */}
                <Route path='/Ochsner' element={<Ochsner />} />
                <Route path='/Meltem' element={<Meltem />} />
                <Route path='/ContactUs' element={<ContactUs />} />
            </Routes>
        </Router>

    );
}

export default Top;