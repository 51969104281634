import React from 'react';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';
import ScrollToTop from "react-scroll-to-top";
import img1 from '../images/relax-home1.webp';
import img2 from '../images/green.webp'
import img3 from '../images/air1.webp'
import img4 from '../images/air2.webp'
import img5 from '../images/tuv.3.webp'
import img6 from '../images/girl_sleeping.webp'
import img7 from '../images/m-umiditate.webp'
import img8 from '../images/m-sanatate.webp'
import img9 from '../images/m-zgomot.webp'
import img10 from '../images/m-economy.webp'
import img11 from '../images/product1.webp'
import img12 from '../images/line.webp'
import MeltemWrgS from '../Components/MeltemWrgS'
import MeltemWrg2 from '../Components/MeltemWrg2'
import Footer from '../Components/footer';
import GoToTop from '../Components/GoToTop';
import MeltemVario from '../Components/MeltemVario';

export default function Ochsner() {
    const { t } = useTranslation();

    return (
        <Container fluid  >
            <ScrollToTop />
            <div id='top' className='row mh1'></div>
            <div class='row row justify-content-md-center mtop'>
                <div class='col-xxl-5 col-xl-6 col-lg-6 col-md-6 col-sm-6 d-none d-sm-block mbg1 '>
                    <h3 className='mh3p1 pt-3'>{t('Ventilation.Meltem.a1')}</h3>
                    <h3 className='mh3p'>{t('Ventilation.Meltem.a2')}</h3>
                </div>

                <div class='col-6 d-block d-sm-none mbg1 '>
                    <h3 className='mh3p1 pt-3'>{t('Ventilation.Meltem.a1')}</h3>
                </div>

                <div class='col-xxl-5 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 '>
                    <img src={img1} class="par2 img-fluid" alt="from nature" />
                </div>
            </div>
            <div className='row mh2 d-none d-md-block'></div>

            <div class='row row justify-content-md-center  pt-2 mtop'>
                <div class='col-12 d-block d-sm-none mbg1'>
                    <h3 className='mh3p pt-3'>{t('Ventilation.Meltem.a2')}</h3>
                </div>
            </div>

            <div className='row  mbg1 mtop pt-2'>
                <div className='col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-sm-2 col-3'>
                    <img src={img2} class="img-fluid" alt="from nature" width={40} height={40} />
                </div>
                <div className='col-xxl-9 col-xl-11 col-lg-11 col-md-11 col-sm-10 col-9'>
                    <h3 className='mh3p1 text-start'>{t('Ventilation.Meltem.a3')}</h3>
                </div>
                <div className='col-xxl-10 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                    <h3 className=' mh3p '>{t('Ventilation.Meltem.a4')}</h3>
                </div>
            </div>

            <div className='row  mbg1 mtop pt-5'>
                <div class='col-xxl-5 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 m-auto '>
                    <h3 className='mh3p1'>{t('Ventilation.Meltem.a5')}</h3>
                    <h3 className='mh3p'>{t('Ventilation.Meltem.a6')}</h3>
                </div>
                <div class='col-xxl-5 col-xl-6 col-lg-6 col-md-6 col-sm-10 col-10 m-auto'>
                    <img src={img3} class="img-fluid" alt="from nature" width={752} height={238} />
                    <img src={img4} class="img-fluid" alt="from nature" width={752} height={238} />
                </div>
            </div>

            <div className='row mbg1 mtop pt-5 flex-column-reverse flex-md-row'>
                <div class='col-xxl-5 col-xl-6 col-lg-6 col-md-6 col-sm-10 col-10 m-auto'>
                    <img src={img5} class="img-fluid" alt="from nature" width={550} height={173} />
                </div>
                <div class='col-xxl-5 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 m-auto '>
                    <h3 className='mh3p1'>{t('Ventilation.Meltem.a7')}</h3>
                    <h3 className='mh3p'>{t('Ventilation.Meltem.a8')}</h3>
                </div>
            </div>

            <div className='row mbg1 mtop pt-5'>
                <div class='col-xxl-5 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 m-auto '>
                    <h3 className='mh3p1'>{t('Ventilation.Meltem.a9')}</h3>
                    <h3 className='mh3p'>{t('Ventilation.Meltem.a10')}</h3>
                </div>
                <div class='col-xxl-5 col-xl-6 col-lg-6 col-md-6 col-sm-10 col-10 m-auto'>
                    <img src={img6} class="img-fluid" alt="from nature" width={690} height={460} />
                </div>
            </div>

            <div className='row mbg1 mtop pt-5'>
                <div class='col-xxl-5 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-start m-auto'>
                    <img src={img7} class="img-fluid" alt="from nature" width={70} height={55} />
                    <h3 className='mh3p1 text-start'>{t('Ventilation.Meltem.a11')}</h3>
                    <h3 className='mh3n'>{t('Ventilation.Meltem.a12')}</h3>
                    <h3 className='mh3n'>{t('Ventilation.Meltem.a13')}</h3>
                    <h3 className='mh3n'>{t('Ventilation.Meltem.a14')}</h3>
                </div>
                <div class='col-xxl-5 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-start m-auto '>
                    <img src={img8} class="img-fluid" alt="from nature" width={70} height={55} />
                    <h3 className='mh3p1 text-start'>{t('Ventilation.Meltem.a15')}</h3>
                    <h3 className='mh3n'>{t('Ventilation.Meltem.a16')}</h3>
                    <h3 className='mh3n'>{t('Ventilation.Meltem.a17')}</h3>
                    <h3 className='mh3n'>{t('Ventilation.Meltem.a18')}</h3>
                </div>
            </div>

            <div className='row mbg1 mtop pb-3' >
                <div class='col-xxl-5 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-start m-auto'>
                    <img src={img9} class="img-fluid" alt="from nature" width={70} height={55} />
                    <h3 className='mh3p1 text-start'>{t('Ventilation.Meltem.a19')}</h3>
                    <h3 className='mh3n'>{t('Ventilation.Meltem.a20')}</h3>
                    <h3 className='mh3n'>{t('Ventilation.Meltem.a21')}</h3>
                    <h3 className='mh3n'>{t('Ventilation.Meltem.a22')}</h3>
                </div>
                <div class='col-xxl-5 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-start m-auto '>
                    <img src={img10} class="img-fluid" alt="from nature" width={70} height={55} />
                    <h3 className='mh3p1 text-start'>{t('Ventilation.Meltem.a23')}</h3>
                    <h3 className='mh3n'>{t('Ventilation.Meltem.a24')}</h3>
                    <h3 className='mh3n'>{t('Ventilation.Meltem.a25')}</h3>
                    <h3 className='mh3n'>{t('Ventilation.Meltem.a26')}</h3>
                </div>
            </div>

            <hr className='hr1' />

            <div className='row mbg1 mtop pt-3'>
                <div class='col-xxl-5 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 m-auto '>
                    <h3 className='mh3p1'>{t('Ventilation.Meltem.b1')}</h3>
                    <h3 className='mh3p'>{t('Ventilation.Meltem.b2')}</h3>
                    <h3 className='mh3p1'>{t('Ventilation.Meltem.b3')}</h3>
                    <h3 className='mh3p'>{t('Ventilation.Meltem.b4')}</h3>
                </div>
                <div class='col-xxl-5 col-xl-6 col-lg-6 col-md-6 col-sm-10 col-10 m-auto text-center'>
                    <img src={img11} class="img-fluid" alt="from nature" width={351} height={460} />
                </div>
            </div>
            <div className='row mbg1 mtop p-5'>
                <img src={img12} class="img-fluid" alt="line" width={1535} height={82} />
            </div>
            <MeltemWrgS />
            <div className='row mbg1 mtop p-5'>
                <img src={img12} class="img-fluid" alt="line" width={1535} height={82} />
            </div>
            <MeltemWrg2 />
            <div className='row mbg1 mtop p-5'>
                <img src={img12} class="img-fluid" alt="line" width={1535} height={82} />
            </div>
            <MeltemVario />
            <div className='row mbg1 mtop p-5'>
                <img src={img12} class="img-fluid" alt="line" width={1535} height={82} />
            </div>
            <GoToTop />
            <Footer />
        </Container>

    )
}