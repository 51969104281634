import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ScrollToTop from "react-scroll-to-top";
import img1 from '../images/vario.webp';

export default function MeltemVario() {
    const { t } = useTranslation();

    return (
        <Container fluid  >
            <ScrollToTop />
            <div id='modele' class='row justify-content-md-center mbg1 '>
                <div class=' col-sm-12 col-12 m-auto text-center d-block d-md-none '>
                    <h3 className='mh3p1'>{t('Ventilation.Meltem.e1')}</h3>
                    <h3 className='mh3p'>{t('Ventilation.Meltem.e2')}</h3>
                    <img src={img1} width={600} height={384} alt="m-wrg-device" class='img-fluid' />
                </div>
                <div class='col-sm-12 col-12 m-auto text-center d-block  d-md-none m-auto text-center'>

                    <div className="d-grid gap-3">
                        <Button href={t('Ventilation.Meltem.linkwrg3-1')} target="_blank" variant="dark" size="lg">
                            {t('Ventilation.Meltem.lwrg3-1')}
                        </Button>
                    </div>
                </div>
            </div>

            <div id='modele' class='row justify-content-md-center mbg1 '>
                <div class='col-xxl-5 col-xl-6 col-lg-6 col-md-6  m-auto text-center d-md-block d-none d-sm-none '>
                    <img src={img1} width={600} height={384} alt="m-wrg-device" class='img-fluid' />
                </div>
                <div class='col-xxl-5 col-xl-6 col-lg-6 col-md-6  m-auto text-center d-md-block d-none d-sm-none m-auto text-center'>

                    <div className="d-grid gap-3">
                    <h3 className='mh3p1'>{t('Ventilation.Meltem.e1')}</h3>
                    <h3 className='mh3p'>{t('Ventilation.Meltem.e2')}</h3>
                        <Button href={t('Ventilation.Meltem.linkwrg3-1')} target="_blank" variant="dark" size="lg">
                            {t('Ventilation.Meltem.lwrg3-1')}
                        </Button>
                    </div>
                </div>
            </div>


        </Container>
    )
}